@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

body {
  background: #1c8ef9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root {
  width: 100%;
  height: 90%;
}


.brand-logo {
  height: 2rem;
  width: 2rem;
  padding: 0.2rem;
}
.brand-logo img{
  height: 2rem;
  width: 2rem;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.navbar-collapse {
  justify-content: flex-end;
}

.auth-wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  margin: auto;
  padding: 4rem 3rem 0 3rem;
}

.auth-main {
  margin: auto;
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 2rem 3rem 0 3rem;
  border-radius: 15px;
  transition: all 0.3s;

}

.auth-secondary {
  width: 450px;
  margin: auto;
  border-radius: 15px;
  padding: 0rem 3rem 2rem 3rem;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 2rem;
}

.auth-main {
  padding-bottom: 1rem;
}

.auth-secondary a {
  color: #CCC;
  font-size: 0.8rem;
}


.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 0.8rem;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.dialogue-footer {
  font-size: 0.8rem;
  padding: 0.5rem 0 2rem 0;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.text-right {
  float: right;
}

.text-left {
  float: left;
}

.page-wrapper {
  min-height: 100%;
  min-height: 100%;
  padding: 2rem 0 2rem 0;
  vertical-align: top;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.page-inner {
  min-height: 100%;
  width: 90%;
  padding-top: 10px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 2rem 1rem 1rem 1rem;
  border-radius: 15px;
  transition: all 0.3s;
  text-align: center;
}

.narrow-page-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.narrow-page-inner {
  width: 50%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 2rem 1rem 1rem 2rem;
  border-radius: 15px;
  transition: all 0.3s;
  text-align: center;
}


@media(max-width: 1200px) {
  .narrow-page-inner {
    width: 80%;
    padding: 2rem 1rem 1rem 2rem;
  }
  .page-inner {
    width: 95%;
    padding: 80px 15px 55px 15px;
    overflow: auto;
  }
}

@media(max-width: 800px) {
  .page-inner {
    width: 100%;
    padding: 80px 15px 55px 15px;
    overflow: auto;
  }
}
@media(max-width: 600px) {
  .narrow-page-inner {
    width: 100%;
    padding: 80px 15px 55px 15px;
  }
  .page-inner {
    padding: 80px 15px 55px 15px;
    overflow: auto;
  }
  .auth-inner {
    width: 100%;
    padding: 2.5rem 0rem 2rem 0rem;
  }
  .auth-main {
    width: 100%;
  }
  .auth-secondary {
    width: 100%;
  }
}
@media(max-width: 400px) {
  .narrow-page-inner {
    width: 100%;
    padding: 80px 5px 55px 5px;
    overflow: auto;
  }
  .auth-main {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
  }
}